/* eslint-disable max-len, no-invalid-this */

import * as d3 from "d3";
import { path as d3Path } from "d3";
import { forceSimulation as d3ForceSimulation } from "d3";
import { forceLink as d3ForceLink } from "d3";
import { forceManyBody as d3ForceManyBody } from "d3";
import { forceCenter as d3ForceCenter } from "d3";

export function init() {
  /*
  document.getElementsByClassName('logo')[0].addEventListener('click', () => {
    window.location.assign('/');
  });
  */
  [].forEach.call(document.querySelectorAll(".navigation"), el => {
    el.addEventListener("mouseover", () => {
      el.childNodes[1].style.opacity = "1";
    });
  });

  [].forEach.call(document.querySelectorAll(".navigation"), el => {
    el.addEventListener("mouseout", () => {
      el.childNodes[1].style.opacity = "0";
    });
  });

  let lastScroll = window.pageYOffset || document.documentElement.scrollTop;

  function handleScrollAndTouch() {
    const isAbs = d3.select(".navigation").classed("position-absolute");
    const diff = window.pageYOffset || document.documentElement.scrollTop;
    if (diff > lastScroll && !isAbs) {
      d3.selectAll(".navigation")
        .transition()
        .delay(function(d, i) {
          return (4 - i) * 50;
        })
        .duration(1000)
        .attr("transform", "translate(300,0)");
    } else {
      d3.selectAll(".navigation")
        .transition()
        .delay(function(d, i) {
          return i * 50;
        })
        .duration(1000)
        .attr("transform", "translate(0,0)");
    }
    lastScroll = diff <= 0 ? 0 : diff;
  }

  document.addEventListener("scroll", handleScrollAndTouch, false);
  document.addEventListener("touchend", handleScrollAndTouch);
}

const min = 0;
const maxI = 47;
const maxJ = 15;

function initialize() {
  const data = [];
  for (let i = min; i < maxI + 1; i++) {
    for (let j = min; j < maxJ + 1; j++) {
      data.push({ x: i, y: j, bit: Math.round(Math.random()) });
    }
  }
  return data;
}

function perturb(data) {
  let c = 0;
  for (let i = min; i < maxI + 1; i++) {
    for (let j = min; j < maxJ + 1; j++) {
      if (Math.random() < 0.01) {
        data[c].bit = data[c].bit == 0 ? 1 : 0;
      }
      c++;
    }
  }
  return data;
}

function philo() {
  const tooltip = d3
    .select("#Philosophy")
    .append("div")
    .attr("id", "text-box")
    .style("opacity", 0)
    .html("");
  let large = 40;
  let medium = 35;
  // const small = 20;
  let strength = -document.body.clientWidth;
  if (window.matchMedia("(max-width: 500px)").matches) {
    large = 28;
    medium = 25;
    strength = 0.4 * strength;
  }
  const nodes = [
    {
      id: gettext("Data"),
      size: large,
      color: d3.rgb(244, 126, 91),
      info: gettext(
        "Data is everywhere but it is often difficult to work with and even harder to learn from.  Our goal is to help make data interactive, explorable, and widely accessible.",
      ),
    },
    {
      id: gettext("Visualization"),
      size: large,
      color: d3.rgb(244, 126, 91),
      info: gettext("Simply put, seeing is believing."),
    },
    {
      id: gettext("Web"),
      size: large,
      color: d3.rgb(244, 126, 91),
      info: gettext(
        "The best way to improve public or private access to your data is through the web.  Whomever your stakeholders may be, by leveraging the web and using open web standards, all they need is an internet connection and a modern browser.",
      ),
    },
    {
      id: gettext("Interactivity"),
      size: large,
      color: d3.rgb(244, 126, 91),
      info: gettext(
        "As your dataset becomes more rich, it becomes more difficult to effectively synthesize all of the information it contains into a set of static graphs. Interactive visualizations are our modus operandi: at Scivero, we ensure that your users can navigate through the different layers of complexity underlying your data, at their own pace.",
      ),
    },
    //
    {
      id: gettext("Analytics"),
      size: medium,
      color: d3.rgb(5, 102, 125),
      info: gettext(
        "The meaning of this term has rapidly evolved over the last twenty years. What began as a paradigm to help managers make data-driven decisions <em>analytics</em> has now, in the age of Big Data and the Internet of Things, become a core feature users expect in every technological interaction: an intelligent, personalized experience.<a target='_blank' href='https://hbr.org/2013/12/analytics-30'><sup>[1]</sup></a>",
      ),
    },
    {
      id: gettext("Creativity"),
      size: medium,
      color: d3.rgb(5, 102, 125),
      info: gettext(
        "Let's be honest: manipulating data can be exceedingly boring.  A little creativity in developing user interactions that allow for quick and independent exploration of a dataset takes a lot of the drudgery out of data work.  We want your users to spend their time thinking about <em>actions</em> as opposed wrestling a dataset into a spreadsheet.",
      ),
    },
    {
      id: gettext("Education"),
      size: medium,
      color: d3.rgb(5, 102, 125),
      info: gettext(
        "Our background is in college-level education.  Be it through our experiences in the classroom or in academic policy development, we have witnessed how the choices made by educational leaders can have a dramatic impact on students.  Our goal is to facilitate making policy decisions based on data and engage as many stakeholders as possible in the process of improving student outcomes.<br /><br /><a target=\"_blank\" href=\"http://www.profweb.ca/en/publications/articles/delving-deeper-into-student-data-visualization-with-program-analytics\">Read more about our Institutional Analytics Platform.</a>",
      ),
    },
    {
      id: gettext("Research"),
      size: medium,
      color: d3.rgb(5, 102, 125),
      info: gettext(
        "Scivero is latin for 'I will have understood'. Our goal is to support your research initiatives; whether it be in academia, or in the management of your institutions, our core mission is to help you and your community understand the stories hidden in your data.",
      ),
    },
    {
      id: gettext("django"),
      size: medium,
      color: d3.rgb(77, 79, 99),
      info: gettext(
        "A fast, secure, and scalable web framework, <a target='_blank' href='https://www.djangoproject.com'>Django</a> prides itself on being built for \"perfectionists with deadlines\". Used by sites such as Mozilla, Instagram, and National Geographic and maintained by a large and dedicated open-source community, Django allows us to offer our clients robust industrial grade web-based solutions for their data needs.",
      ),
    },
    {
      id: gettext("pandas"),
      size: medium,
      color: d3.rgb(77, 79, 99),
      info: gettext(
        "On its way to become the world's most powerful data analysis and manipulation tool, <a target='_blank' href='https://pandas.pydata.org'>pandas</a> is an open-source library that makes  complex analysis of real-world data almost intuitive and is a driving force in the growth of modern data science.",
      ),
    },
    {
      id: gettext("D3"),
      size: medium,
      color: d3.rgb(77, 79, 99),
      info: gettext(
        "The powerful opensource javascript library <a target='_blank' href='https://d3js.org'>D3.js</a> makes it easy to bind data to web elements; visualization possibilities are without limit.  This force-directed graph is created with D3 based on code from its founder <a target='_blank' href='https://bl.ocks.org/mbostock/3037015'>Mike Bostock</a>.",
      ),
    },
    //
    {
      id: gettext("Classroom"),
      size: medium,
      color: d3.rgb(159, 115, 178),
      info: gettext(
        "Technology is playing an increasingly important role in the student learning experience. We build and support opensource web-based tools that make it easier for teachers to implement innovative pedagogical activities without having to worry about technological hurdles. We are the lead developpers for the <a target=\"_blank\" href=https://mydalite.org>myDALITE</a> learning platform.",
      ),
    },
    {
      id: gettext("Institutions"),
      size: medium,
      color: d3.rgb(159, 115, 178),
      info: gettext(
        "Educational policy makers are being held to ever higher standards, with shrinking budgets, while facing the challenge of preparing students for the rapidly evolving global economy.  Administrators need modern, business-style analytics to make data-driven decisions and build the learning institutions of tomorrow.",
      ),
    },
    {
      id: gettext("Publication"),
      size: medium,
      color: d3.rgb(159, 115, 178),
      info: gettext(
        "Funding agencies often require public dissemination of research results.  While this can take the form of press releases or public lectures, these can be ephemeral and limited in scope.  We can help you design and deploy 'living' publications, which automatically update as new data is collected, so you can focus on engaging your stakeholders and telling your story. <br /><br /><a target=\"_blank\" href=http://opieva.ca>Visit on of our latest research group web deployments</a>",
      ),
    },
  ];
  // Research programs generate a phenomenal amount of data, but researchers are often ill equipped to share the fruits of their labour outside of the context of academic publishing.  We can provide a portal to research data along with interactive tools that allow for a dynamic presentation of results.

  const links = [
    { source: gettext("Data"), target: gettext("Education") },
    { source: gettext("pandas"), target: gettext("Analytics") },
    { source: gettext("pandas"), target: gettext("Data") },
    { source: gettext("Visualization"), target: gettext("Creativity") },
    { source: gettext("Creativity"), target: gettext("Interactivity") },
    { source: gettext("Interactivity"), target: gettext("Data") },
    { source: gettext("Interactivity"), target: gettext("Visualization") },
    { source: gettext("Data"), target: gettext("django") },
    { source: gettext("Data"), target: gettext("Research") },
    { source: gettext("django"), target: gettext("Web") },
    { source: gettext("D3"), target: gettext("Visualization") },
    { source: gettext("D3"), target: gettext("Interactivity") },
    { source: gettext("D3"), target: gettext("Web") },
    { source: gettext("Institutions"), target: gettext("Education") },
    { source: gettext("Classroom"), target: gettext("Education") },
    { source: gettext("Publication"), target: gettext("Research") },
  ];

  const maxwidth = 0.9 * document.body.clientWidth;
  const maxheight = 0.7 * document.body.clientHeight;
  const svg = d3
    .select("#workflow")
    .attr("width", maxwidth)
    .attr("height", Math.min(maxwidth, maxheight));
  const width = +svg.attr("width");
  const height = +svg.attr("height");

  svg.selectAll("g").remove();

  const simulation = d3ForceSimulation()
    .force(
      "link",
      d3ForceLink().id(function(d) {
        return d.id;
      }),
    )
    .force(
      "charge",
      d3ForceManyBody()
        .strength(strength)
        .distanceMax(500),
    )
    .force("center", d3ForceCenter(width / 2, height / 2));

  const link = svg
    .append("g")
    .attr("class", "links")
    .selectAll("line")
    .data(links)
    .enter()
    .append("line");

  const node = svg
    .selectAll(".nodes")
    .data(nodes)
    .enter()
    .append("g")
    .attr("class", "nodes")
    .call(
      d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended),
    );

  node
    .append("circle")
    .attr("class", "node-circles")
    .attr("r", function(d) {
      return d.size;
    })
    .attr("fill", function(d) {
      return d.color;
    })
    .on("mouseover", function(d) {
      d3.select(this)
        .transition()
        .duration(200)
        .attr("r", 2 * d.size);
      d3.selectAll(".node-circles").style("opacity", 0.5);
      d3.selectAll(".links").style("opacity", 0.5);
      d3.select(this).style("opacity", 1);
      tooltip.html(d.info);
      tooltip.style("background-color", d.color);
      tooltip
        .transition()
        .duration(200)
        .style("opacity", 1);
    })
    .on("mouseout", function(d) {
      this.setAttribute("r", d.size);
      d3.selectAll(".node-circles").style("opacity", 1);
      d3.selectAll(".links").style("opacity", 1);
    })
    .on("click", function() {
      tooltip.style("opacity", 0);
    });

  node
    .append("text")
    .attr("dy", "5px")
    .style("font-family", "Quicksand, sans-serif")
    .attr("class", "graph-label")
    .style("text-anchor", "middle")
    .style("fill", "white")
    .style("pointer-events", "none")
    .text(function(d) {
      return d.id;
    });

  simulation.nodes(nodes).on("tick", ticked);

  simulation.force("link").links(links);

  function ticked() {
    link
      .attr("x1", function(d) {
        return d.source.x;
      })
      .attr("y1", function(d) {
        return d.source.y;
      })
      .attr("x2", function(d) {
        return d.target.x;
      })
      .attr("y2", function(d) {
        return d.target.y;
      });

    node.attr("transform", function(d) {
      return "translate(" + d.x + "," + d.y + ")";
    });
  }

  function dragstarted(d) {
    if (!d3.event.active) simulation.alphaTarget(0.7).restart();
    d.fx = d.x;
    d.fy = d.y;
  }

  function dragged(d) {
    d.fx = d3.event.x;
    d.fy = d3.event.y;
  }

  function dragended(d) {
    if (!d3.event.active) simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  }
}

export function slideHeader() {
  const header = d3.select("#heading");
  header
    .style("left", "-100%")
    .transition()
    .delay(200)
    .duration(2000)
    .ease(d3.easeCubicOut)
    .style("left", "-0%");
}

export function promptToScroll(id) {
  const path = d3Path();
  const svg = d3.select("#" + id);
  const width = +svg.attr("width");
  const height = +svg.attr("height");
  const g = svg
    .append("g")
    .attr("transform", "translate(" + width / 2 + ", 0)");
  g.append("path")
    .attr("stroke", "white")
    .attr("stroke-width", "2px")
    .attr("fill", "none")
    .attr("d", () => {
      path.moveTo(0, 0);
      path.lineTo(0, height);
      path.lineTo(width / 2, 0.7 * height);
      path.moveTo(0, height);
      path.lineTo(-width / 2, 0.7 * height);
      return path;
    });
}

export function landingScripts() {
  d3.selectAll(".navigation").style("top", "-60px");
  d3.select("body").attr("class", "landing");
  d3.select(".logo")
    .attr("id", "company_name")
    .attr("class", "landing")
    .attr("width", "260");
  $("#company_name").unwrap();
  // Navigation logic
  const logo = d3.select("#company_name");
  const body = d3.select(document.body);
  const tagline = d3.select("#tagline");
  const subtagline = d3.select("#subtagline");
  const ctabutton = d3.select(".btn");
  const flashingcontent = d3.select(".flashing");
  let landing = true;

  // Landing page
  logo
    .transition()
    .duration(1000)
    .style("opacity", 1)
    .style("filter", "blur(20px)")
    .transition()
    .duration(1500)
    .style("filter", "blur(0px)");

  body
    .transition()
    .duration(2000)
    .style("background-color", d3.rgb(5, 102, 125));

  ctabutton
    .transition()
    .delay(1500)
    .duration(1000)
    .style("opacity", 1);

  flashingcontent
    .transition()
    .delay(3500)
    .duration(1000)
    .ease(d3.easeQuadInOut)
    .on("start", function repeat() {
      d3.active(this)
        .style("opacity", 0.5)
        .transition()
        .style("opacity", 1)
        .transition()
        .on("start", repeat);
    });

    tagline
    .transition()
    .delay(1500)
    .duration(1000)
    .style("opacity", 1);

  subtagline
    .transition()
    .delay(1500)
    .duration(1000)
    .style("opacity", 1);
  const widthPercentage = (43 / $(window).width()) * 100;
  const heightPercentage = (64 / $(window).height()) * 100;

  ctabutton.on("click", function() {
    if (landing) {
      slideHeader();
      startArray();
      logo
        .transition()
        .duration(0)
        .style("opacity", 1);
      logo
        .transition()
        .duration(0)
        .style("filter", "blur(0px)");
      logo.style("cursor", "default");
      d3.selectAll(".copyright")
        .transition()
        .duration(2000)
        .style("opacity", 1);

      landing = false;
      tagline
        .transition()
        .duration(100)
        .style("opacity", 0);
      subtagline
        .transition()
        .duration(100)
        .style("opacity", 0);
      ctabutton
        .transition()
        .duration(100)
        .style("opacity", 0);
      logo
        .transition()
        .duration(1500)
        .styleTween("transform", function() {
          return function(t) {
            return (
              "rotate(" +
              -90 * t +
              "deg) translate(" +
              (-50 + 50 * t) + //d'ou il part dans l'écran
              "%," +
              0 +
              "%)"
            );
          };
        })
        .styleTween("bottom", function() {
          return function(t) {
            return 65 - t * (65 - heightPercentage) + "%";
          };
        })
        .styleTween("left", function() {
          return function(t) {
            return 50 - t * (50 + widthPercentage) + "%";
          };
        })
        .attrTween("width", function() {
          return function(t) {
            return 260 - 110 * t;
          };
        });

      d3.selectAll(".navigation")
        .transition()
        .delay(function(d, i) {
          return i * 250;
        })
        .duration(500)
        .style("top", "2px");

      setTimeout(go, 1000, "button5");

      return;
    }
  });

  function activateButton() {
    d3.select("#container").style("width", "95%");
    d3.select("html").style("overflow-y", "scroll")
    d3.select("html").style("scrollbar-width", "none")
    d3.selectAll(".category").style("display", "none");

    document.getElementById("Mission").style.display = "block";

    return;
  }

  function go(button) {
    activateButton.call(document.getElementById(button));

    setTimeout(function() {
      d3.select("#company_name")
        .attr("class", "logo")
        .attr("id", null)
        .style("opacity", null);
    }, 1000);
  }

  // Living data animation
  const svg = d3.select("#living-data");

  const width = +svg.attr("width");
  const height = +svg.attr("height");

  const x = d3
    .scaleLinear()
    .domain([min, maxI])
    .range([0, width]);
  const y = d3
    .scaleLinear()
    .domain([min, maxJ])
    .range([0, height]);

  const g = svg.append("g");

  function update(data) {
    // DATA JOIN WITH KEY FUNCTION
    const bits = g.selectAll(".bit").data(d3.entries(data), function(d) {
      return d.key;
    });

    // UPDATE
    bits.each(function(d, i) {
      if (this.innerHTML != d.value.bit) {
        this.innerHTML = d.value.bit;
        d3.select(this)
          .transition()
          .delay(Math.random() * 1000)
          .duration(10)
          .style("opacity", 1)
          .style("font-size", "12pt")
          .transition()
          .duration(1000)
          .style("opacity", 0.1)
          .style("font-size", "10pt");
      }
    });

    // ENTER + UPDATE
    bits
      .enter()
      .append("text")
      .attr("class", "bit")
      .attr("x", function(d) {
        return x(d.value.x);
      })
      .attr("y", function(d) {
        return y(d.value.y);
      })
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .style("font-family", "Quicksand, sans-serif")
      .style("font-size", "10pt")
      .style("opacity", 0.1)
      .text(function(d) {
        return d.value.bit;
      });
  }

  function startArray() {
    let data = initialize();
    update(data);

    d3.interval(function() {
      data = perturb(data);
      update(data);
    }, 1500);
  }
}

export function philosophyScripts() {
  slideHeader();

  philo();

  window.addEventListener("resize", function() {
    philo();
  });
}

export function missionScripts() {
  d3.select("html").style("overflow-y", "scroll");
  slideHeader();

  const svg = d3.select("#living-data");

  const width = +svg.attr("width");
  const height = +svg.attr("height");

  const x = d3
    .scaleLinear()
    .domain([min, maxI])
    .range([0, width]);
  const y = d3
    .scaleLinear()
    .domain([min, maxJ])
    .range([0, height]);

  const g = svg.append("g");

  function update(data) {
    // DATA JOIN WITH KEY FUNCTION
    const bits = g.selectAll(".bit").data(d3.entries(data), function(d) {
      return d.key;
    });

    // UPDATE
    bits.each(function(d, i) {
      if (this.innerHTML != d.value.bit) {
        this.innerHTML = d.value.bit;
        d3.select(this)
          .transition()
          .delay(Math.random() * 1000)
          .duration(10)
          .style("opacity", 1)
          .style("font-size", "12pt")
          .transition()
          .duration(1000)
          .style("opacity", 0.1)
          .style("font-size", "10pt");
      }
    });

    // ENTER + UPDATE
    bits
      .enter()
      .append("text")
      .attr("class", "bit")
      .attr("x", function(d) {
        return x(d.value.x);
      })
      .attr("y", function(d) {
        return y(d.value.y);
      })
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .style("font-family", "Quicksand, sans-serif")
      .style("font-size", "10pt")
      .style("opacity", 0.1)
      .text(function(d) {
        return d.value.bit;
      });
  }

  let data = initialize();
  update(data);

  d3.interval(function() {
    data = perturb(data);
    update(data);
  }, 1500);
}

export function portfolioDesktopScripts(portfolioMobileURL) {
  goToRightVersion();

  function goToRightVersion() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width <= height && width < 750) {
      window.location.replace(portfolioMobileURL);
    } else {
      return;
    }
  }

  window.addEventListener("resize", function() {
    goToRightVersion();
  });

  let currentThumbnail = document.getElementById("first-thumbnail");
  let currentTextClass = "first-thumbnail-content";

  [].forEach.call(document.querySelectorAll(".scroll-nav-thumb"), el => {
    el.addEventListener("click", () => {
      if (el.classList.contains("selected")) {
        return;
      } else {
        const thumbId = parseInt(el.id.substr(el.id.length - 1)) - 1;
        const thumb = document.getElementsByClassName("thumbnails")[thumbId];
        scrollToElement(thumb);
      }
    });
  });

  [].forEach.call(document.querySelectorAll(".scroll-nav-card"), el => {
    el.addEventListener("click", () => {
      if (el.classList.contains("selected")) {
        return;
      } else {
        const cardId = parseInt(el.id.substr(el.id.length - 1)) - 1;
        const card = document.getElementsByClassName("text-container")[cardId];
        scrollToElement(card);
      }
    });
  });

  function scrollToElement(el) {
    el.scrollIntoView();
  }

  function viewableThumbnail() {
    const thumbnails = document.getElementsByClassName("thumbnails");
    for (let i = 0; i < thumbnails.length; i++) {
      if (checkInView(thumbnails[i]) == true) {
        const thumbNavs = document.getElementsByClassName("scroll-nav-thumb");
        [].forEach.call(thumbNavs, function(el) {
          el.classList.remove("selected");
        });
        thumbNavs[i].classList.add("selected");
        return thumbnails[i];
      }
    }
  }

  function viewableText() {
    const cards = document.getElementsByClassName("text-container");
    for (let i = 0; i < cards.length; i++) {
      if (checkInView(cards[i]) == true) {
        const cardNavs = document.getElementsByClassName("scroll-nav-card");
        [].forEach.call(cardNavs, function(el) {
          el.classList.remove("selected");
        });
        cardNavs[i].classList.add("selected");
        return cards[i];
      }
    }
  }

  function checkInView(elem) {
    const container = $(elem).parent();
    const contHeight = container.height();

    const elemTop = $(elem).offset().top - container.offset().top;
    const elemBottom = elemTop + $(elem).height();

    const isTotal = elemTop >= 0 && elemBottom <= contHeight;

    return isTotal;
  }

  function findRightThumbnail() {
    const el = viewableThumbnail();
    if (el == null || viewableThumbnail() == currentThumbnail) {
      return;
    } else if (el == document.getElementById("first-thumbnail")) {
      scrollToElement(document.getElementById("first-desc"));
      currentTextClass = "first-thumbnail-content";
    } else if (el == document.getElementById("second-thumbnail")) {
      scrollToElement(document.getElementById("second-desc"));
      currentTextClass = "second-thumbnail-content";
    } else if (el == document.getElementById("third-thumbnail")) {
      scrollToElement(document.getElementById("third-desc"));
      currentTextClass = "third-thumbnail-content";
    }
    currentThumbnail = el;
  }

  function findRightText() {
    const el = viewableText();
    if (el == null || el.classList.contains(currentTextClass)) {
      return;
    } else if (el.classList.contains("first-thumbnail-content")) {
      scrollToElement(document.getElementById("first-thumbnail"));
      currentTextClass = "first-thumbnail-content";
    } else if (el.classList.contains("second-thumbnail-content")) {
      scrollToElement(document.getElementById("second-thumbnail"));
      currentTextClass = "second-thumbnail-content";
    } else if (el.classList.contains("third-thumbnail-content")) {
      scrollToElement(document.getElementById("third-thumbnail"));
      currentTextClass = "third-thumbnail-content";
    }
    currentThumbnail = viewableThumbnail();
  }

  document.getElementById("thumbnail").addEventListener("scroll", function() {
    findRightThumbnail();
  });
  document
    .getElementById("text-content")
    .addEventListener("scroll", function() {
      findRightText();
    });

  slideHeader();
}

export function portfolioMobileScripts(portfolioDesktopURL) {
  goToRightVersion();

  function goToRightVersion() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width <= height && width < 800) {
      return;
    } else {
      window.location.replace(portfolioDesktopURL);
    }
  }

  window.addEventListener("resize", function() {
    goToRightVersion();
  });

  let currentTextClass = "first-thumbnail-content";

  [].forEach.call(document.querySelectorAll(".thumbnail-button"), el => {
    el.addEventListener("click", () => {
      if (el.classList.contains("selected")) {
        return;
      } else {
        const txtId = parseInt(el.id.substr(el.id.length - 1)) - 1;
        const txt = document.getElementsByClassName("text-container")[
          txtId * 3
        ];
        scrollToElement(txt);
      }
    });
  });

  [].forEach.call(document.querySelectorAll(".scroll-nav-card"), el => {
    el.addEventListener("click", () => {
      if (el.classList.contains("selected")) {
        return;
      } else {
        const cardId = parseInt(el.id.substr(el.id.length - 1)) - 1;
        const card = document.getElementsByClassName("text-container")[cardId];
        scrollToElement(card);
      }
    });
  });

  function scrollToElement(el) {
    el.scrollIntoView();
  }

  function viewableText() {
    const cards = document.getElementsByClassName("text-container");
    for (let i = 0; i < cards.length; i++) {
      if (checkInView(cards[i]) == true) {
        const cardNavs = document.getElementsByClassName("scroll-nav-card");
        [].forEach.call(cardNavs, function(el) {
          el.classList.remove("selected");
        });
        cardNavs[i].classList.add("selected");
        return cards[i];
      }
    }
  }

  function checkInView(elem) {
    const container = $(elem).parent();
    const contHeight = container.height();

    const elemTop = $(elem).offset().top - container.offset().top;
    const elemBottom = elemTop + $(elem).height();

    const isTotal = elemTop >= 0 && elemBottom <= contHeight;

    return isTotal;
  }

  function findRightText() {
    const el = viewableText();
    if (el == null || el.classList.contains(currentTextClass)) {
      return;
    } else if (el.classList.contains("first-thumbnail-content")) {
      const thumbs = document.getElementsByClassName("thumbnail-button");
      [].forEach.call(thumbs, function(el) {
        el.classList.remove("selected");
      });
      thumbs[0].classList.add("selected");
      currentTextClass = "first-thumbnail-content";
    } else if (el.classList.contains("second-thumbnail-content")) {
      const thumbs = document.getElementsByClassName("thumbnail-button");
      [].forEach.call(thumbs, function(el) {
        el.classList.remove("selected");
      });
      thumbs[1].classList.add("selected");
      currentTextClass = "second-thumbnail-content";
    } else if (el.classList.contains("third-thumbnail-content")) {
      const thumbs = document.getElementsByClassName("thumbnail-button");
      [].forEach.call(thumbs, function(el) {
        el.classList.remove("selected");
      });
      thumbs[2].classList.add("selected");
      currentTextClass = "third-thumbnail-content";
    }
  }

  document
    .getElementById("text-content")
    .addEventListener("scroll", function() {
      findRightText();
    });

  slideHeader();
}

export function galleryScripts(quebecEducationData, maleIcon, femaleIcon) {
  quebecEducationData = "./../data/qc-poly-with-data.json";
  slideHeader();
  d3.select("html")
    .style("overflow-y", "scroll")
    .style("overflow-x", "hidden");

  promptToScroll("scrollPrompt");

  const arrow = d3.select("#scrollPrompt");

  function bouncingArrow() {
    arrow
      .transition()
      .duration(700)
      .attr("transform", "translate(0,-30)")
      .transition()
      .duration(1500)
      .attr("transform", "translate(0,0)")
      .ease(d3.easeBounce)
      .on("end", bouncingArrow);
  }

  document.getElementById("prompt").addEventListener("click", function() {
    window.scrollTo({ top: 9999, behavior: "smooth" });
  });

  bouncingArrow();

  function isSmallerVersion() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width <= height && width < 800) {
      return true;
    } else {
      return false;
    }
  }

  if (isSmallerVersion()) {
    d3.selectAll("table")
      .style("padding", "5px")
      .style("font-size", "5pt");
    d3.selectAll("th")
      .style("padding", "5px")
      .style("font-size", "5pt");
    d3.selectAll("td")
      .style("padding", "5px")
      .style("font-size", "5pt");
  }

  let hasScrolledDown = false;

  function handleScroll() {
    const html = document.querySelector("html");
    if (
      window.innerHeight + window.pageYOffset > html.scrollHeight - 300 &&
      hasScrolledDown == false
    ) {
      d3.select(".sample-data")
        .transition()
        .duration(2000)
        .style("left", "-150%");

      hasScrolledDown = true;
      d3.select("#description").remove();

      setTimeout(function() {
        intro2();
      }, 1100);
    }
  }

  window.addEventListener("scroll", handleScroll);

  $(document).ready(function() {
    $(window).scroll(function() {
      $("table").css("opacity", 1 - $(window).scrollTop() / 1000);
    });
  });

  function intro1() {
    d3.select(".figure-caption").style("opacity", "0");

    d3.select("#gallery1").attr("height", "0");

    const container = d3.select("#container");

    container.style("position", "absolute");
  }

  function intro2() {
    d3.select("#gallery1").attr("height", "500");
    const container = d3.select("#container");

    container.style("position", "fixed");

    container.selectAll(".intro-1").remove();

    setTimeout(function() {
      if (isSmallerVersion()) {
        plotMobile(quebecEducationData);
      } else {
        plot(quebecEducationData, maleIcon, femaleIcon);
      }
      window.scrollTo(0, 0);
      d3.select("html").style("overflow", "hidden");
    }, 1000);

    window.addEventListener("resize", function() {
      if (isSmallerVersion()) {
        plotMobile(quebecEducationData);
      } else {
        plot(quebecEducationData, maleIcon, femaleIcon);
      }
      window.scrollTo(0, 0);
      d3.select("html").style("overflow", "hidden");
    });
  }

  let hasClickedOnMap = false;

  function changeHeaderOnClick() {
    if (hasClickedOnMap == false) {
      const heading = d3.select("#caption");
      heading.transition().style("right", "10px");

      setTimeout(function() {
        heading
          .transition()
          .duration(1500)
          .style("opacity", "0");
      }, 1000);

      setTimeout(function() {
        heading
          .transition()
          .duration(1500)
          .style("opacity", "0.3");
      }, 2600);
    }
    hasClickedOnMap = true;
  }

  function plotMobile(inputData) {
    d3.select("#prompt").remove();

    d3.select("#gallery1")
      .attr("height", "500")
      .style("margin-top", "15%");

    d3.select("html").style("visibility", "visible");

    const container = d3.select("#container");
    container.select("#caption").remove();
    container.select("i").remove();
    container.style("position", "fixed");
    container.selectAll(".intro-1").remove();

    d3.select(".figure-caption").style("opacity", "1");

    const maxwidth = 0.9 * document.body.clientWidth;
    const maxheight = 0.65 * document.body.clientHeight;
    const svg = d3
      .select("#gallery1")
      .attr("width", Math.min(maxwidth, maxheight))
      .attr("height", Math.min(maxwidth, maxheight));

    d3.json(inputData, function(data) {
      const filters = ["Total (Homme-femme)", "Homme", "Femme"];
      let f = 0;

      const ages = [
        "15 ans et plus",
        "15 à 19 ans",
        "20 à 29 ans",
        "30 à 44 ans",
        "45 à 54 ans",
        "55 à 64 ans",
        "65 ans et plus",
      ];
      let a = 0;

      function nextFilter() {
        f++;
        if (f > filters.length - 1) {
          f = 0;
        }
        return;
      }

      const width = svg.attr("width");
      const height = svg.attr("height");

      const projection = d3
        .geoConicEqualArea()
        .parallels([45, 60])
        .rotate([90, 0])
        .fitSize([width, height], data);

      svg.selectAll("g").remove();
      svg.selectAll("text").remove();
      svg.selectAll("#icon").remove();

      svg
        .append("text")
        .attr("id", "region")
        .attr("x", 0.95 * width)
        .attr("y", 0.95 * height)
        .attr("text-anchor", "end")
        .text("");

      const color = d3
        .scaleOrdinal([
          d3.rgb(159, 115, 178),
          d3.rgb(250, 240, 112),
          d3.rgb(248, 172, 67),
          d3.rgb(86, 87, 46),
          d3.rgb(226, 243, 240),
        ])
        .domain(["0", "1", "2", "3", "4"]);

      const pieG = svg
        .append("g")
        .attr("id", "piechart")
        .attr(
          "transform",
          "translate(" + 0.7 * width + "," + 0.05 * height + ")",
        );

      function piechart(slice) {
        // Partly based on https://bl.ocks.org/mbostock/3887235
        svg.select("#pie-title").remove();

        const radius = 0.12 * width;

        const pie = d3
          .pie()
          .sort(null)
          .value(function(d) {
            return d.N;
          });

        const path = d3
          .arc()
          .outerRadius(radius - 5)
          .innerRadius(0);

        d3.arc()
          .outerRadius(radius)
          .innerRadius(0.3 * radius);

        const title = d3
          .arc()
          .outerRadius(radius)
          .innerRadius(1.3 * radius);

        const arc = pieG.selectAll(".arc").data(
          pie(
            slice
              .filter(function(d) {
                return d.Sexe == filters[f];
              })
              .filter(function(d) {
                return d.Age == ages[a];
              })
              .slice(1, 5),
          ),
        );

        arc
          .transition()
          .duration(500)
          .attrTween("d", function(d) {
            const f = d3.interpolate(
              this.getAttribute("startAngle"),
              d.startAngle,
            );
            const g = d3.interpolate(
              this.getAttribute("endAngle"),
              d.endAngle,
            );
            this.setAttribute("startAngle", d.startAngle);
            this.setAttribute("endAngle", d.endAngle);
            return function(t) {
              d.startAngle = f(t);
              d.endAngle = g(t);
              return path(d);
            };
          });

        // Careful not to access d on text, but d on arc!
        arc.each(function(d) {
          d3.select("#data_" + d.index)
            .transition()
            .duration(500)
            .attr("transform", "translate(" + title.centroid(d) + ")")
            .attr("text-anchor", function() {
              if (title.centroid(d)[0] > 0) {
                return "start";
              } else {
                return "end";
              }
            })
            .text(function() {
              if ((50 * (d.endAngle - d.startAngle)) / Math.PI > 5) {
                return (
                  d.value +
                  " (" +
                  parseFloat(
                    (50 * (d.endAngle - d.startAngle)) / Math.PI,
                  ).toFixed(1) +
                  "%)"
                );
              } else {
                return;
              }
            });
          arc.on("click", function() {
            a++;
            if (a > ages.length - 1) {
              a = 0;
            }
            piechart(slice);
          });

          d3.select("#label_" + d.index)
            .transition()
            .duration(500)
            .attr("transform", "translate(" + title.centroid(d) + ")")
            .attr("text-anchor", function() {
              if (title.centroid(d)[0] > 0) {
                return "start";
              } else {
                return "end";
              }
            })
            .text(function() {
              if ((50 * (d.endAngle - d.startAngle)) / Math.PI > 5) {
                return d.data.Scolarite;
              } else {
                return;
              }
            });
        });

        arc.exit().remove();

        arc
          .enter()
          .append("g")
          .append("path")
          .attr("class", "arc")
          .attr("d", path)
          .attr("startAngle", function(d) {
            return d.startAngle;
          })
          .attr("endAngle", function(d) {
            return d.endAngle;
          })
          .attr("stroke", "rgba(190,228,220,0.5)")
          .attr("fill", function(d) {
            return color(d.index);
          })
          .on("click", function() {
            a++;
            if (a > ages.length - 1) {
              a = 0;
            }
            piechart(slice);
          });

        arc
          .enter()
          .append("text")
          .attr("transform", function(d) {
            return "translate(" + title.centroid(d) + ")";
          })
          .attr("dy", "8pt")
          .attr("text-anchor", function(d) {
            if (title.centroid(d)[0] > 0) {
              return "start";
            } else {
              return "end";
            }
          })
          .attr("class", "data-label")
          .attr("id", function(d) {
            return "data_" + d.index;
          })
          .text(function(d) {
            return (
              d.value +
              " (" +
              parseFloat((50 * (d.endAngle - d.startAngle)) / Math.PI).toFixed(
                1,
              ) +
              "%)"
            );
          })
          .style("fill", "white");

        arc
          .enter()
          .append("text")
          .attr("transform", function(d) {
            return "translate(" + title.centroid(d) + ")";
          })
          .attr("class", "plot-label")
          .attr("id", function(d) {
            return "label_" + d.index;
          })
          .attr("text-anchor", function(d) {
            if (title.centroid(d)[0] > 0) {
              return "start";
            } else {
              return "end";
            }
          })
          .text(function(d) {
            return d.data.Scolarite;
          })
          .style("fill", "white");

        svg
          .append("text")
          .attr("id", "pie-title")
          .attr("x", 0.9 * width)
          .attr("y", "-12%")
          .attr("text-anchor", "end")
          .text(filters[f] + " - " + ages[a])
          .style("fill", "white");

        return;
      }

      // HERE IN EDITING
      svg
        .append("g")
        .selectAll("path")
        .data(data.features)
        .enter()
        .append("path")
        .attr("id", function(d) {
          return "region_" + d.properties.Region_ID;
        })
        .attr("class", "region")
        .attr("selected", "false")
        .attr("fill", d3.rgb(190, 228, 220, 0.2))
        .attr("opacity", 0.5)
        .attr("stroke", "rgb(124, 203, 181)")
        .attr("stroke-width", 1)
        .style("cursor", "pointer")
        .attr("d", d3.geoPath(projection))
        .on("mouseover", function(d) {
          const region = this.id;
          d3.selectAll("#" + region).attr("opacity", 0.75);
        })
        .on("mouseout", function(d) {
          const region = this.id;
          d3.selectAll("#" + region).attr("opacity", 0.5);
        })
        .on("click", function(d) {
          changeHeaderOnClick();
          if (this.getAttribute("selected") == "true") {
            nextFilter();
          } else {
            const region = this.id;
            d3.selectAll(".region")
              .attr("selected", "false")
              .transition()
              .duration(100)
              .attr("opacity", 0.5)
              .attr("fill", d3.rgb(190, 228, 220, 0.2));
            d3.selectAll("#" + region)
              .transition()
              .duration(500)
              .attr("opacity", 1)
              .attr("fill", d3.rgb(190, 228, 220, 1));
            d3.select("#region").text(d.properties.Region);
            d3.select(this).attr("selected", "true");
          }
          piechart(d.properties.data);
        });
    });
  }

  function plot(inputData, iconM, iconF) {
    d3.select("#prompt").remove();

    d3.selectAll(".navigation")
      .transition()
      .delay(function(d, i) {
        return i * 50;
      })
      .duration(1000)
      .attr("transform", "translate(0,0)");

    d3.select("#gallery1")
      .attr("height", "500")
      .style("margin-top", "auto");

    d3.select("html").style("visibility", "visible");

    const container = d3.select("#container");
    container.select("#caption").remove();
    container.select("i").remove();
    container.style("position", "fixed");
    container.selectAll(".intro-1").remove();

    d3.select(".figure-caption")
      .transition()
      .duration(2000)
      .style("opacity", "1");

    const maxwidth = 0.9 * document.body.clientWidth;
    const maxheight = 0.65 * document.body.clientHeight;
    const svg = d3
      .select("#gallery1")
      .attr("width", Math.min(maxwidth, maxheight))
      .attr("height", Math.min(maxwidth, maxheight));

    svg.style("opacity", "0");

    d3.json(inputData, function(data) {
      const filters = ["Total (Homme-femme)", "Homme", "Femme"];
      let f = 0;

      const ages = [
        "15 ans et plus",
        "15 à 19 ans",
        "20 à 29 ans",
        "30 à 44 ans",
        "45 à 54 ans",
        "55 à 64 ans",
        "65 ans et plus",
      ];
      let a = 0;

      function nextFilter() {
        f++;
        if (f > filters.length - 1) {
          f = 0;
        }
        if (f == 0) {
          man.style("opacity", "1");
          woman.style("opacity", "1");
        } else if (f == 1) {
          man.style("opacity", "1");
          woman.style("opacity", "0.5");
        } else {
          man.style("opacity", "0.5");
          woman.style("opacity", "1");
        }
        return;
      }

      const width = svg.attr("width");
      const height = svg.attr("height");

      const projection = d3
        .geoConicEqualArea()
        .parallels([45, 60])
        .rotate([90, 0])
        .fitSize([width, height], data);

      svg.selectAll("g").remove();
      svg.selectAll("text").remove();

      svg
        .append("text")
        .attr("id", "region")
        .attr("x", 0.95 * width)
        .attr("y", 0.95 * height)
        .attr("text-anchor", "end")
        .text("");

      const color = d3
        .scaleOrdinal([
          d3.rgb(159, 115, 178),
          d3.rgb(250, 240, 112),
          d3.rgb(248, 172, 67),
          d3.rgb(86, 87, 46),
          d3.rgb(226, 243, 240),
        ])
        .domain(["0", "1", "2", "3", "4"]);

      const pieG = svg
        .append("g")
        .attr("id", "piechart")
        .attr(
          "transform",
          "translate(" + 1.0 * width + "," + 0.22 * height + ")",
        );

      svg.selectAll("#icon").remove();
      const man = svg
        .append("image")
        .attr("id", "icon")
        .attr("height", 0.07 * Math.min(maxwidth, maxheight))
        .attr("width", 0.07 * Math.min(maxwidth, maxheight))
        .attr("x", 1.0 * width)
        .attr("y", width / 2)
        .attr("xlink:href", iconM)
        .style("opacity", "1")
        .style("width", "7%");

      const woman = svg
        .append("image")
        .attr("id", "icon")
        .attr("height", 0.07 * Math.min(maxwidth, maxheight))
        .attr("width", 0.07 * Math.min(maxwidth, maxheight))
        .attr("x", 1.08 * width)
        .attr("y", width / 2)
        .attr("xlink:href", iconF)
        .style("opacity", "1")
        .style("width", "7%");

      function piechart(slice) {
        man.on("click", function() {
          if (woman.style("opacity") == "1" && man.style("opacity") == "0.5") {
            man.style("opacity", "1");
            f = 0;
            piechart(slice);
          } else if (
            man.style("opacity") == "1" &&
            woman.style("opacity") == "1"
          ) {
            man.style("opacity", "0.5");
            f = 2;
            piechart(slice);
          }
        });
        woman.on("click", function() {
          if (man.style("opacity") == "1" && woman.style("opacity") == "0.5") {
            woman.style("opacity", "1");
            f = 0;
            piechart(slice);
          } else if (
            man.style("opacity") == "1" &&
            woman.style("opacity") == "1"
          ) {
            woman.style("opacity", "0.5");
            f = 1;
            piechart(slice);
          }
        });

        // Partly based on https://bl.ocks.org/mbostock/3887235
        svg.select("#pie-title").remove();

        const radius = 0.12 * width;

        const pie = d3
          .pie()
          .sort(null)
          .value(function(d) {
            return d.N;
          });

        const path = d3
          .arc()
          .outerRadius(radius - 5)
          .innerRadius(0);

        d3.arc()
          .outerRadius(radius)
          .innerRadius(0.3 * radius);

        const title = d3
          .arc()
          .outerRadius(radius)
          .innerRadius(1.3 * radius);

        const arc = pieG.selectAll(".arc").data(
          pie(
            slice
              .filter(function(d) {
                return d.Sexe == filters[f];
              })
              .filter(function(d) {
                return d.Age == ages[a];
              })
              .slice(1, 5),
          ),
        );

        arc
          .transition()
          .duration(500)
          .attrTween("d", function(d) {
            const f = d3.interpolate(
              this.getAttribute("startAngle"),
              d.startAngle,
            );
            const g = d3.interpolate(
              this.getAttribute("endAngle"),
              d.endAngle,
            );
            this.setAttribute("startAngle", d.startAngle);
            this.setAttribute("endAngle", d.endAngle);
            return function(t) {
              d.startAngle = f(t);
              d.endAngle = g(t);
              return path(d);
            };
          });

        // Careful not to access d on text, but d on arc!
        arc.each(function(d) {
          d3.select("#data_" + d.index)
            .transition()
            .duration(500)
            .attr("transform", "translate(" + title.centroid(d) + ")")
            .attr("text-anchor", function() {
              if (title.centroid(d)[0] > 0) {
                return "start";
              } else {
                return "end";
              }
            })
            .text(function() {
              if ((50 * (d.endAngle - d.startAngle)) / Math.PI > 5) {
                return (
                  d.value +
                  " (" +
                  parseFloat(
                    (50 * (d.endAngle - d.startAngle)) / Math.PI,
                  ).toFixed(1) +
                  "%)"
                );
              } else {
                return;
              }
            });
          arc.on("click", function() {
            a++;
            if (a > ages.length - 1) {
              a = 0;
            }
            piechart(slice);
          });
          svg.select("#pie-title").on("click", function() {
            a++;
            if (a > ages.length - 1) {
              a = 0;
            }
            piechart(slice);
          });

          d3.select("#label_" + d.index)
            .transition()
            .duration(500)
            .attr("transform", "translate(" + title.centroid(d) + ")")
            .attr("text-anchor", function() {
              if (title.centroid(d)[0] > 0) {
                return "start";
              } else {
                return "end";
              }
            })
            .text(function() {
              if ((50 * (d.endAngle - d.startAngle)) / Math.PI > 5) {
                return d.data.Scolarite;
              } else {
                return;
              }
            });
        });

        arc.exit().remove();

        arc
          .enter()
          .append("g")
          .append("path")
          .attr("class", "arc")
          .attr("d", path)
          .attr("startAngle", function(d) {
            return d.startAngle;
          })
          .attr("endAngle", function(d) {
            return d.endAngle;
          })
          .attr("stroke", "rgba(190,228,220,0.5)")
          .attr("fill", function(d) {
            return color(d.index);
          })
          .on("click", function() {
            a++;
            if (a > ages.length - 1) {
              a = 0;
            }
            piechart(slice);
          });

        arc
          .enter()
          .append("text")
          .attr("transform", function(d) {
            return "translate(" + title.centroid(d) + ")";
          })
          .attr("dy", "8pt")
          .attr("text-anchor", function(d) {
            if (title.centroid(d)[0] > 0) {
              return "start";
            } else {
              return "end";
            }
          })
          .attr("class", "data-label")
          .attr("id", function(d) {
            return "data_" + d.index;
          })
          .text(function(d) {
            return (
              d.value +
              " (" +
              parseFloat((50 * (d.endAngle - d.startAngle)) / Math.PI).toFixed(
                1,
              ) +
              "%)"
            );
          })
          .style("fill", "white");

        arc
          .enter()
          .append("text")
          .attr("transform", function(d) {
            return "translate(" + title.centroid(d) + ")";
          })
          .attr("class", "plot-label")
          .attr("id", function(d) {
            return "label_" + d.index;
          })
          .attr("text-anchor", function(d) {
            if (title.centroid(d)[0] > 0) {
              return "start";
            } else {
              return "end";
            }
          })
          .text(function(d) {
            return d.data.Scolarite;
          })
          .style("fill", "white");

        svg
          .append("text")
          .attr("id", "pie-title")
          .attr("text-anchor", "end")
          .text(ages[a])
          .attr("transform-origin", "50% 50%")
          .attr(
            "transform",
            "translate(" + 1.15 * width + "," + 0.42 * height + ")",
          )
          .style("fill", "white");

        return;
      }

      // HERE IN EDITING
      svg
        .append("g")
        .selectAll("path")
        .data(data.features)
        .enter()
        .append("path")
        .attr("id", function(d) {
          return "region_" + d.properties.Region_ID;
        })
        .attr("class", "region")
        .attr("selected", "false")
        .attr("fill", d3.rgb(190, 228, 220, 0.2))
        .attr("opacity", 0.5)
        .attr("stroke", "rgb(124, 203, 181)")
        .attr("stroke-width", 1)
        .style("cursor", "pointer")
        .attr("d", d3.geoPath(projection))
        .on("mouseover", function(d) {
          const region = this.id;
          d3.selectAll("#" + region).attr("opacity", 0.75);
        })
        .on("mouseout", function(d) {
          const region = this.id;
          d3.selectAll("#" + region).attr("opacity", 0.5);
        })
        .on("click", function(d) {
          changeHeaderOnClick();
          if (
            this.getAttribute("selected") == "true" &&
            pieG.style("opacity") == "1"
          ) {
            nextFilter();
          } else {
            changeHeaderOnClick();
            const region = this.id;
            d3.selectAll(".region")
              .attr("selected", "false")
              .transition()
              .duration(100)
              .attr("opacity", 0.5)
              .attr("fill", d3.rgb(190, 228, 220, 0.2));
            d3.selectAll("#" + region)
              .transition()
              .duration(500)
              .attr("opacity", 1)
              .attr("fill", d3.rgb(190, 228, 220, 1));
            d3.select("#region").text(d.properties.Region);
            d3.select(this).attr("selected", "true");
          }
          if (pieG.style("opacity") == "0") {
            pieG.style("opacity", "1");
            f = f - 1;
            if (f > filters.length - 1) {
              f = 0;
            }
            nextFilter();
          }
          piechart(d.properties.data);
        });
    });

    svg
      .transition()
      .duration(2000)
      .style("opacity", "1");
  }

  intro1();
}

export function teamScripts() {
  slideHeader();
  d3.select("html").style("overflow-y", "scroll");
}

window.addEventListener("DOMContentLoaded", function() {
  init();
});
